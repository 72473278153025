<template>
  <div>
    <div
      class="input-group input-group-sm custom-form-group mb-0"
      v-bind:style="[
        searchMobBtnActive ? { width: '275px' } : { width: '220px' },
      ]"
    >
      <input
        type="text"
        v-model="searchMobile"
        class="form-control"
        id="searchmobInput"
        placeholder="Search By Mobile No."
        :disabled="hidesearchmob"
        aria-label="Search"
        aria-describedby="basic-addon1"
        autocomplete="off"
        maxlength="15"
        @keyup.enter="search(searchMobile)"
        @keypress="mobileSearchFormat"
        style="padding: 6px 15px"
      />
      <button
        type="button"
        class="header-btn-outer btn btn-primary"
        :disabled="searchMobile.length <= 3"
        @click="search(searchMobile)"
        :style="{ width: '50px' }"
      >
        <span v-if="!searchMobLoader"
          ><i class="pi pi-search" aria-hidden="true"></i
        ></span>
        <div
          class="spinner-border text-light custom-spinner-loader-btn"
          role="status"
          v-if="searchMobLoader"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
      <button
        type="button"
        class="header-btn-outer btn btn-primary ms-1"
        @click="searchStop()"
        :style="{ width: '50px' }"
        v-show="searchMobBtnActive"
      >
        <i class="pi pi-times" aria-hidden="true"></i>
      </button>
    </div>
   
  </div>
</template>

<script>
import commonMixin from "../mixins/commonMixin";
export default {
  mixins: [commonMixin],
  props: {
    searchMobBtnActive: {
      type: Boolean,
    },
    searchMobLoader: {
      type: Boolean,
    },
  },
  data() {
    return {
      searchMobile: "",
    };
  },
  methods: {
    searchMob() {
      if (this.searchMobile.trim() !== "") {
        this.$emit("searchMob", this.searchMobile.trim());
      }
    },
    searchStopMob() {
      this.searchMobile= "";
      this.$emit("searchStopMob");
    },
  },
};
</script>

<style>
</style>